<template>
  <div class="lineList">
    <el-breadcrumb separator="/" v-if="type != 'select'">
      <el-breadcrumb-item>礼包列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>ID:</span>
      <el-input
        style="width: 100px"
        v-model="list.packId"
        placeholder="请输入ID"
      ></el-input>
      <span>标题:</span>
      <el-input v-model="list.title" placeholder="请输入标题名称"></el-input>
      <span>所属分类:</span>

      <el-select
        @clear="onClear(2)"
        clearable
        v-model="list.cateId"
        placeholder="请选择"
      >
        <el-option
          v-for="item in cateListA"
          :key="item.cateId"
          :label="item.cateName"
          :value="item.cateId"
        >
        </el-option>
      </el-select>
      <span>状态:</span>
      <el-select
        clearable
        @clear="onClear(1)"
        v-model="list.status"
        placeholder="请选择"
      >
        <el-option
          v-for="item in status"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>是否显示:</span>
      <el-select clearable v-model="list.isShow" placeholder="请选择">
        <el-option
          v-for="item in isShowOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <br />
      <br />
      <span>万旅网显示:</span>
      <el-select clearable v-model="list.wlShow" placeholder="请选择">
        <el-option
          v-for="item in isWlShowOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-button type="primary" @click="getLineList()">查找</el-button>
      <el-button
        type="primary"
        @click="navTo('/addgiftBag')"
        v-if="
          $store.state.powerList.indexOf('travel:info:list:add') !== -1 &&
          type != 'select'
        "
        >添加</el-button
      >
    </div>

    <el-table
      :data="tableData"
      border
      style="width: 100%"
      @selection-change="onSelectionChange"
    >
      <el-table-column align="center" type="selection"></el-table-column>
      <el-table-column
        align="center"
        prop="packId"
        label="ID"
      ></el-table-column>
      <el-table-column align="center" prop="dept" label="排序">
        <template slot-scope="{ row }">
          <el-input
            type="number"
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button v-else class="button-new-tag" @click="onChangeSort(row)">{{
            row.dept
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="title"
        label="标题"
        width="300"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cateName"
        label="所属分类"
        width="150"
      ></el-table-column>
      <el-table-column align="center" prop="showImg" label="展示图">
        <template slot-scope="{ row }">
          <el-image
            class="showimg"
            :preview-src-list="[row.showImg]"
            :src="row.showImg"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="label"
        label="标签"
      ></el-table-column>
      <el-table-column align="center" label="活动时间" width="200">
        <template slot-scope="{ row }">
          <span>{{ row.beginTime + "-" + row.endTime }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="stock"
        label="库存"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strStatus"
        label="状态"
      ></el-table-column>
      <el-table-column align="center" prop="isShow" label="是否显示">
      </el-table-column>
      <el-table-column align="center" prop="wlShow" label="万旅网显示">
      </el-table-column>

      <el-table-column
        align="center"
        label="操作"
        width="250"
        v-if="type != 'select'"
      >
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            v-show="row.status == 2 || row.status == 1"
            @click="onChangeStatus(row, 4)"
            v-if="
              $store.state.powerList.indexOf('pack:info:list:modify') !== -1
            "
            >失效</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-show="row.status == 4 || row.status == 3"
            @click="onChangeStatus(row, 1)"
            v-if="
              $store.state.powerList.indexOf('pack:info:list:modify') !== -1
            "
            >启用</el-button
          >
          <el-button
            size="mini"
            type="primary"
            style="margin: 5px"
            @click="navTo(`/addgiftBag?packId=${row.packId}`)"
            v-if="$store.state.powerList.indexOf('pack:info:list:edit') !== -1"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="delCate(row)"
            v-if="
              $store.state.powerList.indexOf('pack:info:list:delete') !== -1
            "
            >删除</el-button
          >
          <!-- v-if="
                $store.state.powerList.indexOf('travel:specs:list:select') !== -1
              " -->
          <el-button
            size="mini"
            type="primary"
            @click="navTo(`/giftPackageList?packId=${row.packId}`)"
            >套餐详情</el-button
          >
          <el-dropdown style="margin-left:5px">
            <el-button type="primary"   size="mini">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="text"
                  v-if="
                    $store.state.powerList.indexOf('pack:info:list:log') !== -1
                  "
                  @click="navTo(`/giftviewLog?packId=${row.packId}`)"
                  >查看日志</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="text"
                  @click="onShowAddress(row.packId)"
                  >查看页面地址</el-button
                >
              </el-dropdown-item>

              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="text"
                  @click="creatCode(row.packId)"
                  >生成二维码</el-button
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250" v-else>
        <template slot-scope="{ row }">
          <div>
            <el-button size="mini" type="primary" @click="selectRow(row)"
              >确定</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn" v-if="type != 'select'">
      <el-button
        type="primary"
        @click="onChangeStatus1(1)"
        v-if="
          $store.state.powerList.indexOf('pack:info:list:updateList') !== -1
        "
        >批量显示万旅网</el-button
      >
      <el-button
        type="primary"
        @click="onChangeStatus1(0)"
        v-if="
          $store.state.powerList.indexOf('pack:info:list:updateList') !== -1
        "
        >批量隐藏万旅网</el-button
      >
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>

    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input
          style="width: 300px"
          v-model.trim="QRcodeSize"
          placeholder="请输入生成的二维码大小"
        ></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看超级礼包页面地址"
      :visible.sync="showAddress"
      width="35%"
    >
      <div id="address">
        超级礼包页面地址:<el-input
          v-model="pickAddress"
          readonly
          id="wrapper"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-document-copy"
          @click="copyinviteUrl"
          >一键复制</el-button
        >
      </div>
      <div slot="footer">
        <el-button @click="showAddress = false">取 消</el-button>
        <el-button type="primary" @click="showAddress = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import {
  getPackageList,
  modifyPackageStatus,
  modifyDeptPackageStatus,
  getClassifyList,
  deletePackage,
  wanlvPackShow,
  giftCodeImgWx,
} from "../../../api/seckillInfo";
//   import { createEr } from "../../api/erCode";
//   import { selectAllByFatherId } from "../../api/travel";
export default {
  name: "lineList",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pickAddress: "",
      showAddress: false,
      ids: "",
      QRcodeSize: "",
      codeVisible: false,
      travelId: "",
      imgUrl: "",
      showAddress: false,
      address: "",
      ids: "",
      tableData: [],
      pagination: {},
      cateValue: null,
      cateValue1: null,
      cateListA: [],
      cateListB: [],
      cateListC: [],
      isShowOptions: [
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      isWlShowOptions: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      status: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "进行中",
        },
        {
          value: 2,
          label: "已开始",
        },
        {
          value: 3,
          label: "已结束",
        },
        {
          value: 4,
          label: "已失效",
        },
      ],
      pagination: {},
      packId: "",
      list: {
        packId: "0",
        currentPage: 1,
        pageSize: 5,
        title: "",
        status: null,
        cateId: null,
        isShow: 1,
        wlShow: -1,
      },
    };
  },
  // watch: {
  //   cateValue: async function () {
  //     const { data } = await selectAllByFatherId({
  //       fatherId: this.cateValue,
  //     });
  //     this.$store.commit("setLinecateValue", this.cateValue);
  //     this.cateListB = data.data;
  //   },
  //   cateValue1: async function () {
  //     const { data } = await selectAllByFatherId({
  //       fatherId: this.cateValue1,
  //     });
  //     this.$store.commit("setLinecateValue1", this.cateValue1);
  //     this.cateListC = data.data;
  //   },
  //   "list.cateId": function () {
  //     this.$store.commit("setLinecateId", this.list.cateId);
  //   },
  // },
  created() {
    //   this.cateValue = this.$store.state.linecateValue;
    //   this.cateValue1 = this.$store.state.linecateValue1;
    //   this.list.cateId = this.$store.state.cateId;

    this.navTo();
    this.getLineList();
    this.getTravelCate();
  },
  methods: {
    onShowAddress(id) {
      this.pickAddress =
        "seckill/pages/giftBagDetail/giftBagDetail?scene=" + id + "_0";
      this.showAddress = true;
    },

    copyinviteUrl() {
      this.$nextTick(() => {
        var range = document.createRange();
        range.selectNode(document.getElementById("wrapper"));
        var selection = window.getSelection();
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },
    onChangeStatus1(status) {
      if (!this.ids) {
        return this.$message({
          type: "warning",
          message: "请选择要修改的内容!",
        });
      }
      // 是否修改
      this.$confirm("是否要批量修改选中超级礼包的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(this.ids);
          const { data } = await wanlvPackShow({
            packIds: this.ids,
            wlShow: status,
          });
          if (data.code === 0) {
            this.getLineList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {});
    },
    // 跳转行程管理
    toTrip(id) {
      this.$router.push("/trip?travelId=" + id);
    },
    creatCode(id) {
      this.packId = id;
      this.codeVisible = true;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await giftCodeImgWx({
          packId: this.packId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },

    navTo(url) {
      // if (url) {
      //   sessionStorage.setItem("WANLV_TRAVEL_LINELIST_PAGE", this.list.currentPage);
      //   this.$router.push(url);
      // } else {
      //   this.list.currentPage =
      //     Number(sessionStorage.getItem("WANLV_TRAVEL_LINELIST_PAGE")) || 1;
      //   sessionStorage.setItem("WANLV_TRAVEL_LINELIST_PAGE", 1);
      // }
      this.$router.push(url);
    },
    addByCodyLine(travelId) {
      this.$confirm("确定一键复制这条收据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await addByCody({
            travelId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getLineList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    async getLineList() {
      const { data } = await getPackageList(this.list);
      console.log(data);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async handleInputConfirm(row) {
      const { data } = await modifyDeptPackageStatus({
        packId: row.packId,
        dept: Number(row.dept),
      });
      if (data.code === 0) {
        row.inputVisible = false;
        this.getLineList();
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async getTravelCate() {
      const { data } = await getClassifyList();
      this.cateListA = data.data;
    },
    onChangeStatus(row, status) {
      this.$confirm("是否改变这条数据的变化", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await modifyPackageStatus({
            packId: row.packId,
            status,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.getLineList();
          } else {
            this.$message({
              type: "error",
              message: "失败,请稍重试!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delCate(row) {
      this.$confirm("是否删除这个分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await deletePackage({
            packId: row.packId,
          });
          console.log(data);
          if (data.code == 0) {
            this.getLineList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onSelectionChange(selection) {
      //  this.selectList = selection;
      this.ids = selection
        .map((item) => {
          // arr.push(item.travelId);
          return item.packId;
        })
        .join(",");
      console.log(selection);
      // this.ids = arr.join(",");
      this.$emit("change", selection);
    },
    selectRow(row) {
      this.$emit("change", [row]);
      this.$emit("submit", [row]);
    },
    onAddHot(status) {
      console.log(status, "status");
      this.$confirm("是否批量改变这些数据的变化", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        if (this.ids == "") {
          this.$message({
            type: "error",
            message: "请选择要改变的数据!",
          });
          return;
        }
        const { data } = await addHot({
          ids: this.ids,
          hot: status,
        });
        if (data.code == 0) {
          this.$message({
            type: "success",
            message: data.msg,
          });
        } else {
          this.$message({
            type: "error",
            message: "失败,请稍重试!",
          });
        }
      });
    },
    onClear(id) {
      if (id == 1) {
        this.list.status = null;
      } else if (id == 2) {
        this.list.cateId = null;
        this.cateValue = null;
        this.cateValue1 = null;
      } else if (id == 3) {
        this.cateValue1 = null;
        this.cateValue1 = null;
      } else if (id == 4) {
        this.list.cateId = null;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getLineList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getLineList();
    },
  },
};
</script>
  <style lang="less" scoped>
.lineList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 10px 0 40px;
    }
    .el-input {
      width: 200px;
    }
    .el-button {
      margin: 0 20px;
    }
    .el-select {
      width: 150px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .btn {
    float: left;
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;

    float: right;
  }
  #address {
    display: flex;
    align-items: center;

    .el-input {
      width: 350px;
    }
  }
}
</style>
  